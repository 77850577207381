/* policy */

.policy {
    @apply py-[40px] xlx:py-[60px] w-full flex gap-[30px] md:gap-[45px] md:flex-row flex-col md:!px-[30px] xlx:!px-[50px]
}

.policy .content {
    @apply flex flex-col gap-[25px] xlx:gap-[30px] md:order-1 order-2 w-full md:w-[calc(100%-325px)] xlx:w-[calc(100%-355px)]
}

.policy .content-title {
    @apply text-blackDark dark:text-textBlackDark font-bold
}

.policy .content .content-desc {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.policy .content .list-section {
    @apply flex flex-col gap-[15px] xlx:gap-[18px]
}

.policy .content .points-section {
    @apply flex flex-col gap-[12px] xlx:gap-[14px]
}

.policy .content .list {
    @apply flex gap-[10px]
}

.policy .content .list .point-icon {
    @apply min-w-[16px] mt-[4.5px] xlx:mt-[6px]
}

.policy .content .list .sec-point-icon {
    @apply min-w-[9px] mt-[4.5px] xlx:mt-[6px]
}

.policy .menu {
    @apply md:order-2 order-1 w-full md:w-[280px] xlx:w-[310px] h-[365px] shadow-[2px_2px_8px_0px_rgba(255,255,255,0.30),-2px_-2px_4px_0px_rgba(255,255,255,0.50)] dark:shadow-none backdrop-blur-[47.5px] rounded-[18px]
}

.policy .menu .menu-header {
    @apply w-full relative h-[47px]
}

.policy .menu .menu-first-section {
    @apply flex justify-end w-full h-full
}

.policy .menu .menu-second {
    @apply absolute left-[3px] bottom-[-14px]
}

.policy .menu .menu-second svg {
    @apply w-[128px]
}

.policy .menu ._menu-second-path {
    @apply fill-[#f8f8f899] dark:fill-bgDark
}

.policy .menu .menu-third {
    @apply absolute top-0 left-0 bottom-0 w-[120px] flex items-end justify-end pr-[10px] pb-[10px]
}

.policy .menu .menu-first {
    @apply w-[calc(100%-120px)] rounded-t-[18px] h-full dark:bg-bgDark bg-[#f8f8f899]
}

.policy .menu .menu-section {
    @apply w-full rounded-[18px] !rounded-tr-none dark:bg-bgDark bg-[#f8f8f899] h-[calc(100%-47px)] border-t-0 p-[12px] relative flex flex-col items-start
}

.policy .menu .menu-third .title-btn {
    @apply h-[30px] px-[21px] bg-primary text-white shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-base font-bold rounded-[15px] flex items-center justify-center uppercase border dark:border-[#454545] border-[#E4E4E7]
}

.policy .menu .active {
    @apply flex items-center gap-[8px] py-[13px] pl-[20px] pr-[28px] text-blackDark dark:text-textBlackDark font-bold text-base leading-snug bg-[#D9D9D9] bg-opacity-[0.2] w-full rounded-[10px]
}

.policy .menu .active svg {
    @apply stroke-blackDull dark:stroke-textBlackDull
}

.policy .menu .inactive {
    @apply text-blackDull dark:text-textBlackDull text-opacity-[0.7] font-medium text-base leading-snug py-[13px] pl-[34px] pr-[28px] w-full focus:text-blackDark dark:focus:text-textBlackDark hover:text-blackDark dark:hover:text-textBlackDark
}

.policy .menu .bg-img {
    @apply w-full absolute bottom-0 left-0 right-0 rounded-b-[18px] dark:sepia
}

.policy .link {
    @apply hover:underline focus:underline text-primary
}

/* information */
.information {
    @apply py-[40px] xlx:py-[60px] w-full flex gap-[30px] md:gap-[45px] md:flex-row flex-col md:!px-[30px] xlx:!px-[50px]
}

.information .content {
    @apply flex flex-col gap-[25px] xlx:gap-[30px] md:order-1 order-2 w-full md:w-[calc(100%-365px)] xlx:w-[calc(100%-400px)]
}

.information .do_icon_section {
    @apply grid grid-cols-1 xxs:grid-cols-2 mms:grid-cols-3 xl:grid-cols-5 gap-x-[30px] gap-y-[15px] w-full mb-[15px]
}

.information .do_icon_section_card {
    @apply flex flex-col gap-[10px] items-center
}

.information .do_icon_section_card_title {
    @apply text-[#5C5C5C] dark:text-textBlackDull font-semibold text-center
}

.information .donts_title_btn {
    @apply !bg-secRed
}

.information .donts_section {
    @apply !w-full !h-full
}

.information .donts_menu_section {
    @apply !p-[25px] xlx:!p-[30px]
}

.information .dos_title_btn {
    @apply !bg-secGreen
}

.information .dos_section {
    @apply !w-full !h-full
}

.information .dos_menu_section {
    @apply !p-[25px] xlx:!p-[30px]
}

.information ._menu {
    @apply md:order-2 order-1 w-full md:w-[320px] xlx:w-[355px] h-[420px] shadow-[2px_2px_8px_0px_rgba(255,255,255,0.30),-2px_-2px_4px_0px_rgba(255,255,255,0.50)] dark:shadow-none backdrop-blur-[47.5px] rounded-[18px]
}

.information ._menu ._menu-header {
    @apply w-full relative h-[47px]
}

.information ._menu ._menu-first-section {
    @apply flex justify-end w-full h-full
}

.information ._menu ._menu-second {
    @apply absolute left-0 bottom-[-13px]
}

.information ._menu ._menu-third {
    @apply absolute top-0 left-0 bottom-0 w-[160px] flex items-end justify-end pr-[10px] pb-[10px]
}

.information ._menu ._menu-first {
    @apply w-[calc(100%-160px)] rounded-t-[18px] h-full dark:bg-bgDark bg-[#f8f8f899]
}

.information ._menu ._menu-section {
    @apply w-full rounded-[18px] !rounded-tr-none dark:bg-bgDark bg-[#f8f8f899] h-[calc(100%-47px)] border-t-0 p-[12px] relative flex flex-col items-start
}

.information ._menu ._menu-second-path {
    @apply fill-[#f8f8f899] dark:fill-bgDark
}

.information ._menu ._menu-third .title-btn {
    @apply h-[30px] px-[21px] bg-primary text-white shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-base font-bold rounded-[15px] flex items-center justify-center uppercase border dark:border-[#454545] border-[#E4E4E7]
}

.information ._menu .active {
    @apply flex items-center gap-[8px] py-[13px] pl-[20px] pr-[28px] text-blackDark dark:text-textBlackDark font-bold text-base leading-snug bg-[#D9D9D9] bg-opacity-[0.2] w-full rounded-[10px]
}

.information ._menu .active svg {
    @apply stroke-blackDull dark:stroke-textBlackDull
}

.information ._menu .inactive {
    @apply text-blackDull dark:text-textBlackDull text-opacity-[0.7] font-medium text-base leading-snug py-[13px] pl-[34px] pr-[28px] w-full focus:text-blackDark dark:focus:text-textBlackDark hover:text-blackDark dark:hover:text-textBlackDark
}

.information ._menu .bg-img {
    @apply w-full absolute bottom-0 left-0 right-0 rounded-b-[18px] dark:sepia
}

/* table */
.table-data {
    @apply w-full
}

.table-data .section {
    @apply overflow-x-auto block
}

.table-data .section .subsection {
    @apply inline-block min-w-full
}

.table-data .section .subsection .section-content {
    @apply overflow-hidden
}

.table-data .section .subsection .section-content table {
    @apply min-w-full 
}

.table-data .section .subsection .section-content table thead {
    @apply bg-primary
}

.table-data .section .subsection .section-content table thead tr {
    @apply h-[32px]
}

.table-data .section .subsection .section-content table thead tr .tbl-heading {
    @apply px-[15px] xlx:px-[20px] h-full text-white font-semibold text-base text-left
}

.table-data .section .subsection .section-content table thead tr .tbl-heading-center {
    @apply px-[15px] xlx:px-[20px] h-full text-white font-semibold text-base text-center
}

.table-data .section .subsection .section-content table tbody tr {
    @apply border border-[#E7E8EC] dark:border-[#454545] bg-white dark:bg-bgDarkDull
}

.table-data .section .subsection .section-content table tbody td {
    @apply border-r border-[#E7E8EC] dark:border-[#454545] bg-white dark:bg-bgDarkDull
}

.table-data .section .subsection .section-content table tbody tr .tbl-row {
    @apply px-[15px] xlx:px-[20px] py-[10px] xlx:py-[15px] text-blackDull dark:text-textBlackDull text-base font-medium text-left
}

.table-data .section .subsection .section-content table tbody tr .tbl-row p {
    @apply px-[15px] xlx:px-[20px] py-[10px] xlx:py-[15px] text-blackDull dark:text-textBlackDull text-base font-medium text-left
}

.table-data .section .subsection .section-content table tbody tr .tbl-row-center {
    @apply px-[15px] xlx:px-[20px] py-[10px] xlx:py-[15px] text-blackDull dark:text-textBlackDull text-base font-medium text-center
}

.table-data .section .subsection .section-content table tbody tr .link {
    @apply hover:underline focus:underline text-primary
}

.table-data .section .subsection .section-content table tbody tr .police-st {
    @apply  xlx:w-[270px] xlx:min-w-[270px] w-[230px] min-w-[230px]
}

.table-data .section .subsection .section-content table tbody tr .police-st-sec {
    @apply flex flex-col gap-[4px]
}

.table-data .section .subsection .section-content table tbody tr .police-st-sec h5 {
    @apply uppercase font-semibold text-blackDull dark:text-textBlackDull
}

.table-data .section .subsection .section-content table tbody tr .police-st-sec h6 {
    @apply font-medium text-blackDull dark:text-textBlackDull
}

.table-data .section .subsection .section-content table tbody tr .metro-st {
    @apply w-[280px] min-w-[280px]
}

.table-data .section .subsection .section-content table tbody tr .metro-st-sec {
    @apply flex items-center gap-[6px] flex-wrap
}

.table-data .section .subsection .section-content table tbody tr .metro-st .st {
    @apply bg-[rgba(217,217,217,0.35)] dark:bg-[#d4d5d838] rounded-[20px] px-[11px] py-[0.5px] flex items-center justify-center w-fit text-blackDull dark:text-textBlackDull font-semibold text-sm
}

.table-data .section .subsection .section-content table tbody tr .contact-no {
    @apply text-secDarkRed font-semibold whitespace-nowrap
}

.table-data .section .subsection .section-content table tbody tr .st-count h5 {
    @apply font-semibold text-blackDull dark:text-textBlackDull
}

.security {
    @apply grid grid-cols-1 xs:grid-cols-2 md:grid-cols-1 mms:grid-cols-2 xl:grid-cols-4 gap-[12px] w-full mb-[20px]
}

.security .card1 {
    @apply col-span-1 xs:row-span-2 md:row-span-1 mms:row-span-2 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px]
}

.security .card2 {
    @apply col-span-1 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px] flex flex-col justify-between gap-[46px]
}

.security .card3 {
    @apply col-span-1 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px] flex flex-col
}

.security .card4 {
    @apply col-span-1 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px] flex flex-col justify-between gap-[46px]
}

.security .card5 {
    @apply col-span-1 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px] flex flex-col justify-between gap-[46px]
}

.security .card6 {
    @apply xs:col-span-2 md:col-span-1 mms:col-span-2 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px] flex flex-col justify-between gap-[46px] relative
}

.security .first-title {
    @apply text-blackDark dark:text-textBlackDark font-bold
}

.security .sec-contact {
    @apply text-danger dark:text-darkDanger font-bold 1.5xl:text-2xl
}

.security .other-contact {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.security .go-portal-sec {
    @apply flex items-center gap-[6px]
}

.security .go-portal-sec a {
    @apply text-primary font-semibold hover:underline focus:underline
}

.security .contact-wrap {
    @apply pt-[12px] flex flex-col gap-[8px]
}

.security .other-contact-sec {
    @apply flex flex-col gap-[4px]
}

.security .go-portal-sec .arr {
    @apply min-w-[12px]
}

.security .go-portal-sec-svg {
    @apply absolute bottom-0 right-[30px]
}


/* faqs */
.faqs .active-category {
    @apply h-[32px] xlx:h-[37px] px-[20px] bg-primary text-white shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-base font-semibold rounded-[20px] flex items-center justify-center border dark:border-[#454545] border-[#E4E4E7] focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e]
}

.faqs .inactive-category {
    @apply h-[32px] xlx:h-[37px] px-[20px] text-blackDull dark:text-textBlackDull shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-base font-semibold rounded-[20px] flex items-center justify-center border dark:border-[#454545] border-[#E4E4E7] focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]
}

.faqs .category {
    @apply flex items-center justify-center w-full flex-wrap gap-[10px] mb-[35px]
}

.faqs {
    @apply flex flex-col items-center justify-center w-full
}

.accordion {
    @apply w-full xlx:w-[1085px] border border-[#E7E8EC]
}

.accordion-item {
    @apply w-full border-b border-[#E7E8EC] px-[25px] xlx:px-[35px] py-[12px] xlx:py-[15px] relative
}

.accordion-item input[type='checkbox'] {
    display: none;
  }

.accordion:last-child {
    @apply border-b-0
}

.accordion-item-header {
    @apply w-full cursor-pointer text-base text-blackDark dark:text-textBlackDark font-bold after:content-["\002B"] after:text-[1.5rem] after:absolute after:right-[25px] after:xlx:right-[35px] hover:underline focus:underline block after:text-primary
}

.toggle:checked+.accordion-item-header::after {
    @apply content-["\2212"] text-blackDull dark:text-textBlackDull
}

.accordion-item-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.toggle:checked + .accordion-item-header + .accordion-item-body {
    max-height: fit-content;
}

.toggle:checked + .accordion-item-header {
    @apply text-primary
}

.accordion-item-body-content {
    @apply pt-[10px] xlx:pt-[15px] w-full   
}

.accordion-item-body-content,
.accordion-item-body-content * {
    @apply text-base font-medium text-blackDull dark:text-textBlackDull
}

/* facilities */
.facilities {
    @apply py-[40px] xlx:py-[60px] w-full md:!px-[30px] xlx:!px-[50px] grid grid-cols-1 xlsm:grid-cols-2 msm:grid-cols-3 1.5xl:grid-cols-4 gap-[30px]
}

.facilities .card {
    @apply rounded-[20px] w-full h-full
}

.facilities .card1 {
    @apply bg-[#EF6E0B] focus:shadow-[0_0_0_4px_#EF6E0B5e] hover:shadow-[0_0_0_4px_#EF6E0B5e] dark:focus:shadow-[0_0_0_4px_#ffffff5e] dark:hover:shadow-[0_0_0_4px_#ffffff5e]
}

.facilities .card2 {
    @apply bg-[#084A76] focus:shadow-[0_0_0_4px_#084A765e] hover:shadow-[0_0_0_4px_#084A765e] dark:focus:shadow-[0_0_0_4px_#ffffff5e] dark:hover:shadow-[0_0_0_4px_#ffffff5e]
}

.facilities .card3 {
    @apply bg-[#262626] focus:shadow-[0_0_0_4px_#2626265e] hover:shadow-[0_0_0_4px_#2626265e] dark:focus:shadow-[0_0_0_4px_#ffffff5e] dark:hover:shadow-[0_0_0_4px_#ffffff5e]
}

.facilities .card .content-sec .border-bottom1 {
    @apply border-[#C15A0B]
}

.facilities .card .content-sec .border-bottom2 {
    @apply border-[#0A2E45]
}

.facilities .card .content-sec .border-bottom3 {
    @apply border-[#525252]
}

.facilities .card .img {
    @apply rounded-t-[20px] w-full
}

.facilities .card .content-sec {
    @apply pt-[8px] xlx:pt-[12px] pb-[20px] px-[25px] w-full
}

.facilities .card .content-sec .title {
    @apply text-white font-bold max-w-[250px]
}

.facilities .card .content-sec .border-bottom {
    @apply border-b my-[12px] xlx:my-[15px]
}

.facilities .card .content-sec .icon-content-sec {
    @apply flex flex-wrap gap-[10px]
}

.facilities .card .content-sec .icon-content {
    @apply flex items-center gap-[10px]
}

.facilities .card .content-sec .icon-content svg {
    @apply min-w-[20px]
}

.facilities .card .content-sec .icon-content .subtitle {
    @apply text-white font-semibold
}

/* web info manager */
.web-info {
    @apply md:!px-[30px] xlx:!px-[50px] py-[50px] xlx:py-[70px] flex mms:items-center gap-[30px] mms:gap-[50px] xlx:gap-[100px] mms:justify-center mms:flex-row flex-col
}

.corporate-web-info {
    @apply !pt-[40px] xlx:!pt-[60px] !pb-[70px] lg:!pb-[90px] xlx:!pb-[110px]
}

.web-info .content-sec {
    @apply flex flex-col gap-[15px]
}

.web-info .content-sec h4 {
    @apply text-primary font-bold
}

.web-info .content-sec h5 {
    @apply text-blackDull dark:text-textBlackDull font-medium max-w-[500px]
}

.web-info .content-card {
    @apply bg-white dark:bg-bgDarkDull dark:shadow-[0px_8px_28px_-6px_#ffffff1f] rounded-[20px] shadow-[0px_8px_22px_0px_rgba(24,39,75,0.12)] p-[30px] xlx:p-[40px] w-full mms:w-auto mms:max-w-[400px] msm:max-w-[450px] xlx:max-w-[470px]
}

.content-card-jfdss {
    @apply w-full md:w-auto md:min-w-[400px] msm:min-w-[450px] xlx:min-w-[470px] h-[200px] sm:h-[220px] xlx:h-[250px] flex flex-col justify-center relative
}

.content-card-jfdss svg {
    @apply absolute right-0 bottom-0 !h-[198px] !w-[170px] xlx:!w-auto xlx:!h-auto
}

.web-info .content-card h3 {
    @apply text-primary font-semibold pb-[4px]
}

.web-info .content-card .post {
    @apply text-blackDull dark:text-textBlackDull font-medium pb-[15px]
}

.web-info .content-card .contact-sec {
    @apply flex items-center gap-[15px] flex-wrap
}

.web-info .content-card .contact {
    @apply flex items-center gap-[8px]
}

.web-info .content-card .contact h5 {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.web-info .content-card .contact-address {
    @apply flex gap-[8px] pt-[15px]
}

.web-info .content-card .contact-address h5 {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.web-info .link {
    @apply hover:underline focus:underline text-blackDull dark:text-textBlackDull
}

.about_us_icon_text h3 {
    @apply text-[1.75rem] text-white font-bold
}

.about_us_icon_text h6 {
    @apply font-bold text-white
}

.content-page > div:first-child {
    @apply !py-[15px] !px-0
}

/* corporate pages */
.corporate-pages {
    @apply w-full pt-[30px] xsm:pt-[40px] xlx:pt-[60px] pb-[60px] xsm:pb-[70px] lg:pb-[90px] xlx:pb-[110px] flex gap-[25px] mms:gap-[40px] mms:!px-[30px] mms:flex-row flex-col
}

/* menu */

.corporate-pages .menu-sec {
    @apply w-full mms:min-w-[340px] xlx:min-w-[375px] xlx:w-[375px] mms:w-[340px] h-fit mms:order-2 order-1
}

.corporate-pages .menu-sec .menu-title {
    @apply px-[28px] py-[9px] w-full bg-[#212E4E] flex items-center relative
}

.corporate-pages .menu-sec .menu-title .bg {
    @apply absolute top-0 bottom-0 right-0
}

.corporate-pages .menu-sec .menu-title h5 {
    @apply text-white font-bold uppercase
}

.corporate-pages .menu-sec .active {
    background: linear-gradient(90deg, #E4F7FF 0%, #FFF 100%);
}

.dark .corporate-pages .menu-sec .active {
    background: linear-gradient(90deg, #364d57 0%, #2A2C38 100%);
}

.corporate-pages .menu-sec .active {
    @apply text-primaryText dark:text-white border-0 px-[16px] py-[8px] flex items-center gap-[8px] text-base font-bold
}

.corporate-pages .menu-sec .active svg {
    @apply stroke-primaryText dark:stroke-white min-w-[5px] min-h-[11px]
}

.corporate-pages .menu-sec .inactive {
    @apply text-blackDull dark:text-textBlackDull border border-[#E7E8EC] dark:border-gray-700 border-t-0 px-[28px] py-[7.5px] hover:text-primaryText hover:dark:text-white flex items-center gap-[8px] text-base font-bold
}

/* function of the cvo */

.corporate-pages .corporate-content-sec {
    @apply w-full mms:w-[calc(100%-380px)] xlx:w-[calc(100%-415px)] mms:order-1 order-2 flex flex-col gap-[30px]
}

.corporate-pages .corporate-main-content-sec {
    @apply w-full flex flex-col gap-[18px]
}

.corporate-pages .paragraph {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.corporate-pages .paragraph-link {
    @apply text-[#0E5A81] dark:text-primary font-medium text-base hover:underline focus:underline
}

.corporate-pages .paragraph-sec {
    @apply w-full flex flex-col gap-[15px]
}

.corporate-pages .paragraph-sec .paragraph-title {
    @apply text-blackDull dark:text-textBlackDull font-bold uppercase
}

.corporate-pages .paragraph-sec .paragraph-content {
    @apply flex gap-[10px]
}

.corporate-pages .paragraph-sec .paragraph-content svg {
    @apply stroke-primaryText dark:stroke-white mt-[5px] xlx:mt-[6px] min-w-[5px] min-h-[11px]
}

/* table */
.table-G7zmG {
    @apply overflow-x-auto block w-full
}

.table-C0mda {
    @apply inline-block min-w-full
}

.table-GfybP {
    @apply overflow-hidden
}

.table-i2PDH {
    @apply min-w-full
}

.thead-tr-G7zmG {
    @apply border border-[#E7E8EC] dark:border-gray-700 bg-[#F8F8F8] dark:bg-[#3a3f47] border-b-0
}

.thead-tr-G7zmG-dark {
    @apply !bg-primary *:!text-white text-white
}

.th-srno-G7zmG {
    @apply px-[8px] xlx:px-[11px] py-[9.5px] text-primaryText dark:text-white font-semibold text-sm text-center
}

.th-G7zmG {
    @apply px-[11px] xlx:px-[15px] py-[9.5px] text-primaryText dark:text-white font-semibold text-sm text-center
}

.tbody-tr-G7zmG {
    @apply border border-[#E7E8EC] dark:border-gray-700
}

.td-srno-G7zmG {
    @apply px-[8px] xlx:px-[11px] py-[11px] text-blackDull dark:text-textBlackDull font-medium text-base border-r border-[#E7E8EC] dark:border-gray-700 xlx:min-w-[70px] xlx:w-[70px] min-w-[65px] w-[65px] text-center
}

.td-G7zmG {
    @apply px-[11px] xlx:px-[15px] py-[11px] text-blackDull dark:text-textBlackDull font-medium text-base *:text-base border-r border-[#E7E8EC] dark:border-gray-700 text-center
}

.td-last-G7zmG {
    @apply px-[11px] xlx:px-[15px] py-[11px] text-blackDull dark:text-textBlackDull font-medium text-base *:text-base text-center
}

.td-G7zmG ul li,
.td-last-G7zmG ul li {   
    @apply text-base
}

.td-highlight-G7zmG {
    @apply text-primary dark:text-primary bg-gray-50 dark:bg-gray-800
}

.authority-G7zmG {
    @apply text-blackDark dark:text-textBlackDark font-bold pb-[2px]
}

.authority-G7zme {
    @apply text-blackDark dark:text-textBlackDark font-medium
}

.authority-G7zm2 {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.notice-sec {
    @apply flex items-center justify-center max-w-[715px] w-full mx-auto
}

.vigilance-contact-us {
    @apply grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 gap-[15px] msm:gap-[30px] w-full max-w-[1000px] items-center justify-center mx-auto
}

.vigilance-contact-us .first {
    @apply border border-[#E49A56] bg-[#E49A560d] p-[16px] flex flex-col gap-[10px] w-full
}

.vigilance-contact-us .second {
    @apply border border-[#56B9E4] bg-[#56B9E40d] p-[16px] flex flex-col gap-[10px] w-full
}

.vigilance-contact-us .third {
    @apply border border-[#FC8BA3] bg-[#FC8BA30d] p-[16px] flex flex-col gap-[10px] w-full
}

.vigilance-contact-us .name {
    @apply text-primaryText dark:text-white font-bold
}

.vigilance-contact-us .role {
    @apply font-semibold text-blackDull dark:text-textBlackDull
}

.vigilance-contact-us .contact {
    @apply flex items-center gap-[8px]
}

.vigilance-contact-us .contact-no {
    @apply text-[#0097D6] font-medium focus:underline hover:underline
}

.corporate-pages .news-link {
    @apply focus:underline hover:underline text-blackDark dark:text-textBlackDark
}

.corporate-pages .news-list-disc {
    @apply list-disc flex flex-col gap-[10px]
}

.faqs-corpo {
    @apply msm:!flex-row !flex-col
}

.faqs-corpo-sec {
    @apply !w-full msm:!w-[calc(100%-380px)] xlx:!w-[calc(100%-415px)] msm:!order-1 !order-2 !flex !flex-col !gap-[30px]
}

.faqs-corpo-menu-sec {
    @apply !w-full msm:!min-w-[340px] xlx:!min-w-[375px] xlx:!w-[375px] msm:!w-[340px] !h-fit msm:!order-2 !order-1
}

.facts h5 {
    @apply text-blackDark dark:text-textBlackDark font-bold uppercase
}

.facts-sec {
    @apply flex flex-col w-full md:max-w-[800px] border border-[#E7E8EC] dark:border-[#454545]
}

.facts-first {
    @apply border-b dark:border-[#454545] border-[#E7E8EC] w-full py-[15px] px-[30px]
}

.facts-last {
    @apply w-full py-[15px] px-[30px]
}

.facts-title {
    @apply text-blackDull dark:text-textBlackDull font-medium text-base flex items-center gap-[10px] hover:underline focus:underline w-fit
}

.facts-sec svg {
    @apply fill-blackDull dark:fill-white
}

/* project-funding */

.project-funding-chart-sec {
    @apply w-full flex items-center justify-center mt-[20px] md:mt-[50px]
}

.project-funding-chart {
    @apply grid grid-cols-1 md:grid-cols-2 items-center gap-y-[30px] max-w-[800px]
}

.project-funding-chart img {
    @apply md:order-2 order-1
}

.chart-content {
    @apply space-y-[16px] w-full md:order-1 order-2
}

.chart-content span {
    @apply text-base text-blackDull dark:text-textBlackDark font-medium
}

.chart-content-bg {
    @apply w-[12px] min-w-[12px] h-[12px] rounded-full flex-shrink-0
}

.chart-content-sec {
    @apply flex items-center gap-[10px]
}

/* Typical Utility Shifting Plan */
.typical-utility-shifting-plan {
    @apply !grid !grid-cols-1 msm:!grid-cols-2 !gap-x-[15px] !gap-y-[30px]
}

.img-layout {
    @apply border border-[#EAEAEA] dark:border-gray-700 p-[10px] xlx:p-[14px] w-full
}

.img-layout img {
    @apply w-full
}

/* Environmental Benefits */
.env {
    @apply w-full flex flex-col gap-[30px] xlsm:gap-[40px] xlx:gap-[50px]
}

.env-HawOo {
    @apply flex xl:items-center w-full gap-y-[30px] gap-x-[15px] flex-col xl:flex-row xl:justify-between
}

.env-cIDSy {
    @apply text-blackDull dark:text-textBlackDull font-bold xl:max-w-[345px]
}

.env-Ra4WC {
    @apply grid grid-cols-1 xxs:grid-cols-2 md:flex items-end gap-[40px] xl:gap-[80px] xl:justify-end justify-between
}

.env-lPnVY {
    @apply flex flex-col gap-[20px] items-center
}

.env-lPnVY svg {
    @apply min-w-[71px] min-h-[71px]
}

.env-lPnVY h4 {
    @apply text-blackDull dark:text-textBlackDull font-semibold text-center flex flex-col
}

.env-lPnVY h4 span {
    @apply text-[1.375rem]
}

.env-Mog2P {
    @apply w-full flex items-center justify-center
}

.env-Mog2P div {
    @apply border-b border-[#d6d6d6] dark:border-gray-600 max-w-[1150px] w-full
}

.env-rvnTp {
    @apply w-full grid grid-cols-1 xlsm:grid-cols-2 msm:grid-cols-3 xl:grid-cols-4 gap-[15px]
}

.env-61nHR {
    @apply rounded-[15px] border border-[#ECECEC] dark:border-gray-600 shadow-[0px_6px_14px_-6px_rgba(24,39,75,0.12),0px_10px_32px_-4px_rgba(24,39,75,0.10)] min-h-[197px] w-full p-[25px] flex flex-col justify-between gap-[15px]
}

.env-61nHS {
    @apply flex items-center gap-[15px]
}

.env-61nHS svg {
    @apply min-w-[49px]
}

.env-61nHS h6 {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.env-7w4RL {
    @apply flex items-end gap-[15px] flex-wrap
}

.env-7w4RR {
    @apply flex flex-col gap-[8px]
}

.env-7w4RR-2021 {
    @apply text-white font-semibold flex items-center justify-center px-[8px] h-[16px] rounded-[10px] bg-secPink w-fit
}

.env-7w4RR-2031 {
    @apply text-white font-semibold flex items-center justify-center px-[8px] h-[16px] rounded-[10px] bg-secOrange w-fit
}

.env-7w4RR-2041 {
    @apply text-white font-semibold flex items-center justify-center px-[8px] h-[16px] rounded-[10px] bg-secBlue w-fit
}

.env-7w4RR h4 {
    @apply text-blackDull dark:text-textBlackDull font-semibold
}

/* Project Route */
.project-route {
    @apply relative
}

.project-route-aOIIA {
    @apply absolute bottom-[-120px] left-0
}

.project-route-aOIIB {
    @apply flex items-center gap-[30px] xlx:gap-[55px] relative z-[1] msm:flex-row flex-col
}

.project-route-aOIIC {
    @apply flex flex-col gap-[4px] xs:gap-[6px] xlx:gap-[15px]
}

.project-route-aOIIC h1 {
    @apply text-blackDull dark:text-textBlackDull font-bold max-w-[460px]
}

.project-route-aOIID {
    @apply flex flex-col gap-[10px]
}

.project-route-aOIID h5 {
    @apply text-blackDull dark:text-textBlackDull font-medium max-w-[530px]
}

.project-route-aOIIE {
    @apply msm:mt-[-250px] xl:mt-[-300px] xlx:mt-[-330px]
}

.project-route-aOIIE img {
    @apply w-fit
}

.project-route-aOIIF {
    @apply absolute right-0 xsm:right-[40px] bottom-0 xsm:bottom-[100px] flex flex-col gap-[10px]
}

.project-route-aOIIG {
    @apply flex items-center gap-[8px]
}

.project-route-aOIIh {
    @apply xlx:min-w-[12px] xlx:w-[12px] xlx:h-[12px] xs:min-w-[10px] xs:w-[10px] xs:h-[10px] w-[8px] min-w-[8px] h-[8px] rounded-full bg-secGreen
}

.project-route-aOIIl {
    @apply xlx:min-w-[12px] xlx:w-[12px] xlx:h-[12px] xs:min-w-[10px] xs:w-[10px] xs:h-[10px] w-[8px] min-w-[8px] h-[8px] rounded-full bg-[#FCAB02]
}


.project-route-aOIII {
    @apply xlx:min-w-[12px] xlx:w-[12px] xlx:h-[12px] xs:min-w-[10px] xs:w-[10px] xs:h-[10px] w-[8px] min-w-[8px] h-[8px] rounded-full bg-[#C4C4C4]
}

.project-route-aOIIG h5 {
    @apply font-bold text-blackDull dark:text-textBlackDull uppercase leading-[19px] text-xs xs:text-sm xlx:text-base
}

.employees-contribution-hgdks {
    @apply w-full border border-[#D9D9D9] dark:border-[#868484] grid grid-cols-1 msm:grid-cols-2 xlx:grid-cols-4
}

.employees-contribution-hgdko {
    @apply grid grid-cols-1 msm:grid-cols-2 xl:grid-cols-3 border-r-0 border-b-0
}

.employees-contribution-hgdkh {
    @apply w-full relative h-[300px] border-b xlx:border-b-0 msm:border-r border-[#D9D9D9] dark:border-[#868484] focus:bg-[#e5e7eb52] dark:focus:bg-gray-700 hover:bg-[#e5e7eb52] dark:hover:bg-gray-700
}

.employees-contribution-hgdkp {
    @apply w-full relative h-[300px] border-b xlx:border-b-0 xlx:border-r border-[#D9D9D9] dark:border-[#868484] focus:bg-[#e5e7eb52] dark:focus:bg-gray-700 hover:bg-[#e5e7eb52] dark:hover:bg-gray-700
}

.employees-contribution-hgdkq {
    @apply w-full border-b msm:border-b-0 msm:border-r border-[#D9D9D9] dark:border-[#868484] relative h-[300px] focus:bg-[#e5e7eb52] dark:focus:bg-gray-700 hover:bg-[#e5e7eb52] dark:hover:bg-gray-700
}

.employees-contribution-hgdkb {
    @apply w-full relative h-[300px] focus:bg-[#e5e7eb52] dark:focus:bg-gray-700 hover:bg-[#e5e7eb52] dark:hover:bg-gray-700
}

.employees-contribution-hgdko .employees-contribution-hgdkb {
    @apply border-r border-[#D9D9D9]
}

.employees-contribution-hgdko .employees-contribution-hgdkh {
    @apply border-b border-[#D9D9D9] border-r
}

.employees-contribution-hgdko .employees-contribution-hgdkp {
    @apply border-b border-r border-[#D9D9D9]
}

.employees-contribution-hgdko .employees-contribution-hgdkb {
    @apply border-b border-[#D9D9D9]
}

.employees-contribution-hgdkj {
    @apply xl:pt-[30px] h-[calc(100%-118px)] xlx:h-[calc(100%-166px)] w-full flex items-center justify-center
}

.employees-contribution-hgdkj img {
    @apply w-[80px]
}

.employees-contribution-hgdkk {
    @apply p-[24px] flex flex-col gap-[4px] absolute left-0 right-0 bottom-0
}

.employees-contribution-hgdkl {
    @apply text-primaryText dark:text-white font-semibold text-center
}

.md-speaks {
    @apply flex items-center gap-[30px] xl:gap-[35px] xl:!flex-row !flex-col
}

.md-speaks-kdfjl {
    @apply flex items-center justify-center
}

.md-speaks-kdfjs {
    @apply flex flex-col gap-[12px] xl:gap-[15px]
}

.md-speaks-kdfjs h2 {
    @apply text-blackDark font-bold dark:text-textBlackDark
}

.md-speaks-kdfja {
    @apply flex flex-col gap-[8px] xl:gap-[12px]
}

.md-speaks-kdfja h5 {
    @apply text-blackDull dark:text-textBlackDull font-medium max-w-[675px] text-justify
}

.md-speaks-kdfjw {
    @apply flex flex-col xl:gap-[4px]
}

.md-speaks-kdfjw h3,
.md-speaks-kdfjw h6 {
    @apply text-blackDark font-semibold dark:text-textBlackDark
}

.md-speaks-kdfjh {
    @apply flex flex-col justify-end absolute top-0 left-0 bottom-0 right-0 p-[20px]
}

.md-speaks-kdfjh h4 {
    @apply text-blackDark font-extrabold
}

.md-speaks-kdfjh h6 {
    @apply text-blackDark font-semibold
}

.md-speaks-sdfsa {
    @apply max-w-max xl:items-start xl:!flex-row !flex-col xl:mb-[5px]
}

.team {
    @apply flex flex-col gap-[8px]
}

.team h1 {
    @apply text-primaryText dark:text-white font-bold
}

.team h5 {
    @apply text-blackDark dark:text-textBlackDark font-medium text-justify
}

.team-sdfds {
    @apply w-full grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 mms:grid-cols-1 msm:grid-cols-2 xl:grid-cols-3 gap-[20px] xl:gap-[25px] xlx:gap-[30px] xl:pt-[10px]
}

.team-sdfds-ksdss {
    @apply w-full grid grid-cols-1 xs:grid-cols-2 msm:grid-cols-3 xl:grid-cols-4 gap-[17px]
}

.team-sdfdq {
    @apply flex flex-col w-full gap-[12px] xlx:gap-[15px]
}
 
.team-sdfdq img {
    @apply w-full rounded-[18px]
}

.team-sdfdqw {
    @apply w-full bg-[#F6F6F6] dark:bg-bgDark
}

.team-sdfdqw {
    filter: drop-shadow(0px 2px 6px rgba(105, 105, 105, 0.25));
}

.team-sdfdqw img {
    @apply w-full
}

.team-sdfdl {
    @apply flex flex-col gap-[2px]
}

.team-sdfdls {
    @apply p-[20px] !pt-[8px] gap-0
}

.team-sdfdl h5 {
    @apply text-primaryText dark:text-white font-bold
}

.team-sdfdl h5 {
    @apply text-primaryText dark:text-white font-bold
}

.team-sdfdl h6 {
    @apply text-primaryText dark:text-white font-medium
}

.team-sdfdls h5 {
    @apply font-extrabold
}

.team-sdfdls h6 {
    @apply text-blackDull dark:text-textBlackDull font-semibold
}